import { fetchSites } from '@/api/site/site'
import { useSortTitle, useTableOptions } from '@/composables'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Nicename',
      value: 'nicename',
    },
    {
      text: 'Country',
      value: 'country_id',
    },
    {
      text: 'Sharing Distributor No.',
      value: 'distributor_count',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = reactive({
    name: '',
  })

  const siteOptions = computed(() =>
    tableItems.value
      .map(s => ({
        title: s.nicename,
        value: s.id,
      }))
      .sort(useSortTitle),
  )

  const loadData = async (query = {}) => {
    loading.value = true

    try {
      const { data } = await fetchSites({
        ...useTableOptions(options.value),
        ...parseFilters(),
        ...query,
      })

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters[key],
        }),
        {},
      )

  watch([options], () => {
    loadData()
  })

  watch(
    () => filters.name,
    debounce(() => {
      loadData()
    }, 300),
  )

  const authStateOptions = [
    { title: 'User Has Logined.', value: null },
    { title: 'User Has (bought) Quota', value: 'has:user_quota' },
    { title: 'User (does not bought) No Quota', value: 'no:user_quota' },
    { title: 'User Has linked up (e.g. Emma)', value: 'has:linked_up' },
    { title: 'User has not linked up (e.g. Emma)', value: 'no:linked_up' },
  ]

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,
    siteOptions,

    options,
    filters,

    loadData,
    authStateOptions,
  }
}
