<template>
  <div id="site-list">
    <v-breadcrumbs
      divider="/"
      large
      :items="breadcrumbs"
    ></v-breadcrumbs>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- nicename -->
        <template #[`item.nicename`]="{item}">
          <v-chip
            v-if="item.main"
            color="secondary"
            class="mr-2"
          >
            Default
          </v-chip>

          <router-link :to="{ name: 'site-view', params: { id: item.id } }">
            {{ item.nicename }}
          </router-link>
        </template>

        <!-- country_id -->
        <template #[`item.country_id`]="{item}">
          {{ item.country.name.en }}
        </template>

        <!-- distributor_count -->
        <template #[`item.distributor_count`]="{ item }">
          ({{ item.distributor_count }})
          <span
            style="text-wrap: initial;"
          >
            <router-link
              v-for="dist in item.distributors"
              :key="dist.id"
              :to="{ name: 'distributor-view', params: { id: dist.id } }"
              class="ml-2 text--secondary"
            >
              {{ dist.nicename }}
            </router-link>
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <site-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import SiteAside from '@/modules/site/views/site-resource/SiteAside.vue'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import useSiteList from './useSiteList'

export default {
  components: {
    SiteAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData,
    } = useSiteList()

    const actions = ['Edit']

    const breadcrumbs = ref([{ text: 'Site Info', disabled: true, href: '/sites' }])

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const resource = ref(null)

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      loadData,

      singleSelect,
      headerprops,
      showFilter,
      breadcrumbs,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
