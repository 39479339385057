<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <site-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeSite, updateSite } from '@api/site/site'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SiteForm from './SiteForm.vue'

export default {
  components: {
    SiteForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      console.log('form', form)
      loading.value = true
      const request = props.resource
        ? updateSite(props.resource.id, {
          ...form,
        })
        : storeSite({
          ...form,
        })
      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
