<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.nicename"
          label="Nicename"
          outlined
          :rules="[required]"
          class="mt-5"
        />
        <v-autocomplete
          v-if="!resource"
          v-model="form.site_id"
          :items="siteOptions"
          item-text="title"
          item-value="value"
          label="Replicate from"
          outlined
        ></v-autocomplete>
        <v-switch
          v-model="form.main"
          color="primary"
          :label="form.main ? 'is Main' : 'is not Main'"
          :disabled="!resource"
        ></v-switch>
        <CountryIdFilter
          v-model="form"
          :clearable="false"
          :select-base="!resource"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { CountryIdFilter } from '@/components'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useSiteList from '../site-list/useSiteList'

export default {
  components: {
    CountryIdFilter,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('nicename', ''),
      ...field('site_id', null),
      ...field('country_id', null),
      ...field('main', false),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const { siteOptions, loadData, tableItems } = useSiteList()
    loadData({ take: 999 }).then(() => {
      const main = tableItems.value.find(s => s.main)
      if (main) {
        form.value.site_id = main.id
      }
    })

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      siteOptions,
    }
  },
}
</script>
